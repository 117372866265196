<template>
  <header
    v-if="$route.name == 'Home'"
    class="mt-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
  >
    <div
      class="bg-gradient-to-r from-red-400 to-yellow-200 hover:opacity-95 cursor-pointer rounded-lg h-48 md:h-64 shadow px-4 md:px-10 relative overflow-hidden"
    >
      <svg
        class="hidden lg:block absolute right-full transform -translate-y-3/4 translate-x-1/4 lg:translate-x-2/3 opacity-30"
        width="404"
        height="784"
        fill="none"
        viewBox="0 0 404 784"
      >
        <defs>
          <pattern
            id="f210dbf6-a58d-4871-961e-36d5016a0f49"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect
              x="0"
              y="0"
              width="4"
              height="4"
              class="text-yellow-200"
              fill="currentColor"
            />
          </pattern>
        </defs>
        <rect
          width="404"
          height="784"
          fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
        />
      </svg>
      <div
        class="absolute top-1/2 transform -translate-y-1/2 right-3 lg:right-20"
      >
        <img
          class="w-36 md:w-80 h-auto"
          src="/discount_new.png"
          alt="The Ultimate Comparison of Top Coupon Themes"
        /><a href="https://storyset.com/business" class="sr-only"
          >Business illustrations by Storyset</a
        >
      </div>
      <div class="absolute top-1/2 transform -translate-y-1/2">
        <h1
          class="text-gray-800 font-bold text-lg md:text-3xl tracking-tighter"
        >
          The Ultimate Comparison of Top&nbsp;
          <span class="block xl:inline">Coupon Themes</span>
        </h1>
        <a
          href="https://coupontheme.org/blog/the-ultimate-comparison-of-best-coupon-themes/"
          target="_blank"
          class="inline-block mt-4 md:mt-7 px-4 py-2 bg-red-800 hover:bg-red-900 text-xs md:text-lg text-white font-semibold rounded"
        >
          READ MORE
        </a>
      </div>
      <a
        href="https://coupontheme.org/blog/the-ultimate-comparison-of-best-coupon-themes/"
        target="_blank"
        class="w-full h-full absolute top-0 left-0 block bg-transparent"
      ></a>
    </div>
  </header>
  <header v-else class="bg-gray-50">
    <div
      class="max-w-7xl mx-auto py-10 px-4 sm:px-6 lg:px-8 lg:flex justify-between items-center"
    >
      <h1 class="text-2xl font-medium text-gray-700">
        {{
          $route.name == "ThemeDetails"
            ? getTitle($route.params.slug)
            : $route.name
        }}
      </h1>
      <nav class="flex mt-3 lg:mt-0" aria-label="Breadcrumb">
        <ol role="list" class="flex items-center space-x-1">
          <li>
            <router-link to="/" class="text-gray-400 hover:text-yellow-600">
              <HomeIcon class="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              <span class="sr-only">Home</span>
            </router-link>
          </li>
          <li v-if="$route.name == 'ThemeDetails'" class="flex items-center">
            <ChevronRightIcon
              class="flex-shrink-0 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <router-link
              to="/"
              class="ml-1 text-sm font-medium text-gray-500 hover:text-yellow-600"
              >Shop</router-link
            >
          </li>
          <li class="flex items-center">
            <ChevronRightIcon
              class="flex-shrink-0 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <router-link
              :to="$route.path"
              class="ml-1 text-sm font-medium text-gray-500 hover:text-yellow-600"
              >{{
                $route.name == "ThemeDetails"
                  ? getTitle($route.params.slug)
                  : $route.name
              }}</router-link
            >
          </li>
        </ol>
      </nav>
    </div>
  </header>
</template>

<script>
import { HomeIcon, ChevronRightIcon } from "@heroicons/vue/outline";
import ThemeTitle from "@/modules/theme.js";
export default {
  name: "PageHeader",
  components: {
    HomeIcon,
    ChevronRightIcon,
  },
  setup() {
    const { getTitle } = ThemeTitle();
    return {
      getTitle,
    };
  },
};
</script>

<style></style>
