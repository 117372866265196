<template>
  <div>
    <Disclosure as="nav" class="bg-white relative" v-slot="{ open }">
      <div
        class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
        ref="pageNav"
        id="top"
      >
        <div class="flex items-center justify-between h-16">
          <div class="flex-shrink-0">
            <router-link to="/">
              <img class="h-8 w-auto" src="/favicon.png" alt="Coupon Theme" />
            </router-link>
          </div>
          <div class="hidden md:flex items-baseline space-x-4">
            <template v-for="item in navigation" :key="item.name">
              <router-link
                v-if="item.href"
                :to="item.href"
                class="text-gray-600 hover:bg-gray-100 hover:text-yellow-600 px-3 py-2 rounded-sm text-sm font-medium"
                >{{ item.name }}</router-link
              >
              <a
                target="_blank"
                v-else
                :href="item.link"
                class="text-gray-600 hover:bg-gray-100 hover:text-yellow-600 px-3 py-2 rounded-sm text-sm font-medium"
                >{{ item.name }}</a
              >
            </template>
          </div>
          <div class="-mr-2 flex md:hidden">
            <!-- Mobile menu button -->
            <DisclosureButton
              @click="$emit('clicked')"
              :class="[
                open
                  ? 'text-yellow-600 outline-none border border-dotted border-gray-300'
                  : 'text-gray-600',
                'inline-flex items-center justify-center p-2 rounded-sm text-xs font-medium',
              ]"
            >
              <MenuIcon
                v-if="!open"
                class="inline-block h-4 w-4 mr-2"
                aria-hidden="true"
              />
              <XIcon
                v-else
                class="inline-block h-4 w-4 mr-2"
                aria-hidden="true"
              />
              {{ open ? "Close" : "Menu" }}
            </DisclosureButton>
          </div>
        </div>
      </div>
      <transition
        enter-active-class="transition ease-out duration-500"
        enter-from-class="transform opacity-50 scale-y-50"
        enter-to-class="transform opacity-100 scale-y-100"
        leave-active-class="transition ease-in duration-300"
        leave-from-class="transform opacity-100 scale-y-100"
        leave-to-class="transform opacity-50 scale-y-50"
      >
        <DisclosurePanel
          class="md:hidden w-full bg-white z-20 shadow-lg origin-top absolute top-full"
        >
          <div class="pt-2 divide-y divide-gray-200">
            <DisclosureButton
              v-for="item in navigation"
              as="div"
              :key="item.name"
              class="block w-full rounded-sm text-sm font-medium"
            >
              <router-link
                v-if="item.href"
                :to="item.href"
                class="w-full pl-10 py-4 block"
                >{{ item.name }}</router-link
              >
              <a
                target="_blank"
                v-else
                :href="item.link"
                class="w-full pl-10 py-4 block"
                >{{ item.name }}</a
              >
            </DisclosureButton>
          </div>
        </DisclosurePanel>
      </transition>
    </Disclosure>
  </div>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { MenuIcon, XIcon } from "@heroicons/vue/outline";
import navigation from "@/modules/navigation";

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    MenuIcon,
    XIcon,
  },
  setup() {
    const { menuNavigation, pageNav } = navigation();
    return {
      navigation: menuNavigation,
      pageNav,
    };
  },
};
</script>

<style scoped>
a.router-link-exact-active {
  @apply text-yellow-600;
}
</style>
