import { createRouter, createWebHistory } from "vue-router";
const availableThemes = [
  "clipmydeals",
  "mythemeshop-coupon-theme",
  "couponxl",
  "coupon-wp",
  "couponer",
  "couponis",
  "couponxxl",
  "rehub",
  "kupon",
  "clipper",
  "premiumpress-coupon-theme",
];

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
    pathToRegexpOptions: { strict: true },
    title: `Login `,
  },
  {
    path: "/product/:slug/",
    name: "ThemeDetails",
    component: () => import("../views/ThemeDetails.vue"),
    pathToRegexpOptions: { strict: true },
    beforeEnter: (to, from, next) => {
      if (!availableThemes.includes(to.params.slug)) next({ path: "/404/" });
      else next();
    },
  },
  {
    path: "/product/:slug",
    redirect:"/product/:slug/",
  },
  {
    path: "/about",
    redirect:"/about/",
  },
  {
    path: "/contact",
    redirect:"/contact/",
  },
  {
    path: "/privacy-policy",
    redirect:"/privacy-policy/",
  },
  {
    path: "/about/",
    name: "About",
    component: () => import("../views/About.vue"),
    pathToRegexpOptions: { strict: true },
  },
  {
    path: "/contact/",
    name: "Contact",
    component: () => import("../views/Contact.vue"),
    pathToRegexpOptions: { strict: true },
  },
  {
    path: "/privacy-policy/",
    name: "Privacy Policy",
    component: () => import("../views/PrivacyPolicy.vue"),
    pathToRegexpOptions: { strict: true },
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("../views/NotFound.vue"),
  },
  {
    path: "/404/",
    name: "NotFound",
    component: () => import("../views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;
    return { top: 0 };
  },
});

export default router;
