<template>
  <a
    style="top: 90vh; right: 5vh"
    href="#top"
    class="fixed p-2 lg:p-4 bg-gray-900 bg-opacity-60 hover:bg-opacity-95 text-white rounded-sm z-50"
  >
    <ChevronUpIcon class="w-6 h-6" />
  </a>
</template>

<script>
import { ChevronUpIcon } from "@heroicons/vue/outline";
export default {
  name: "NavigateToTop",
  components: {
    ChevronUpIcon,
  },
};
</script>
