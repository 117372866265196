<template>
  <footer>
    <div class="bg-gray-800">
      <nav
        class="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8 flex flex-wrap justify-center"
        aria-label="Footer"
      >
        <router-link
          v-for="item in footerNavigation"
          :key="item.name"
          :to="item.href"
          class="px-5 py-2 text-base text-gray-300 hover:text-white"
        >
          {{ item.name }}
        </router-link>
      </nav>
    </div>
    <p
      class="text-center bg-gray-900 py-8 px-4 sm:px-6 lg:px-8 text-sm text-gray-400 md:mt-0 md:order-1"
    >
      <span class="font-bold">Disclaimer:</span>&nbsp;CouponTheme.org only tries
      to consolidate various information given on Theme websites and other media
      to help make buyers an informed decision. We try our best to accurate
      information as much as possible, and we also make our best efforts to keep
      the feature lists updated. However, such content is not free from
      misinformation or misunderstanding. Buyers are advised to verify such
      information before making a purchase, and we are not to be held
      responsible in any way for any misunderstanding, miscommunications or
      inaccuracies. If you find any incorrect information on our website, feel
      free to contact us and point it out, so that we can update the content.
      Additionally, theme names & other content may be registered trademarks of
      respective owners, and we do not claim ownership or association with any
      of these.
    </p>
  </footer>
</template>

<script>
import navigation from "@/modules/navigation";
export default {
  name: "PageFooter",
  setup() {
    const { footerNavigation } = navigation();
    return {
      footerNavigation,
    };
  },
};
</script>
