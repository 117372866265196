<template>
  <div class="bg-gray-100 min-h-full">
    <Navigation />
    <PageHeader />
    <main class="bg-gray-100 min-h-80vh">
      <router-view :key="$route.fullPath" />
    </main>
    <PageFooter />
    <NavigateToTop v-if="buttonToTop" />
  </div>
</template>
<script>
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import Navigation from "@/components/Navigation.vue";
import NavigateToTop from "./components/NavigateToTop.vue";
import navigation from "@/modules/navigation";
import { useHead } from "@vueuse/head";

export default {
  components: {
    Navigation,
    PageHeader,
    PageFooter,
    NavigateToTop,
  },
  setup() {
    const { buttonToTop } = navigation();
    useHead({
      title: "Find, Compare & Buy all Top Coupon Themes | CouponTheme.org",
    });
    return {
      buttonToTop,
    };
  },
};
</script>
