import { onMounted, onBeforeUnmount, ref } from "@vue/runtime-core";

const footerNavigation = [
  { name: "About", href: "/about/" },
  { name: "Contact", href: "/contact/" },
  { name: "Privacy Policy", href: "/privacy-policy/" },
  { name: "Sitemap", href: "/sitemap.xml" },
];

const menuNavigation = [
  { name: "Home", href: "/" },
  { name: "About", href: "/about/" },
  { name: "Blog", link: "https://coupontheme.org/blog/" },
  { name: "Contact", href: "/contact/" },
];
const pageNav = ref(null);
var buttonToTop = ref(false);

const toggleMenuButton = () => {
  if (
    document.body.scrollTop > pageNav.value.offsetHeight ||
    document.documentElement.scrollTop > pageNav.value.offsetHeight
  )
    buttonToTop.value = true;
  else buttonToTop.value = false;
};
export default () => {
  onMounted(() => {
    window.addEventListener("scroll", toggleMenuButton);
  });

  onBeforeUnmount(() => {
    window.removeEventListener("scroll", toggleMenuButton);
  });
  return {
    pageNav,
    buttonToTop,
    menuNavigation,
    footerNavigation,
  };
};
